import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { ThemeContext } from '../utils';

const PostCard = ({ post }) => {
  const [theme] = useContext(ThemeContext);
  const title = post.frontmatter.title || post.fields.slug;
  return (
    <article
      className={`duration-300 transition-colors rounded shadow-md w-full flex flex-col ${theme.backgroundExtraLight} ${theme.text}`}
    >
      {post.frontmatter.featuredImage && (
        <Link className="no-underline cursor-pointer" to={`/blog${post.fields.slug}`}>
          <Image className="rounded rounded-b-none h-40" sizes={post.frontmatter.featuredImage.childImageSharp.sizes} />
        </Link>
      )}
      <ul className="px-3 pt-4 text-xs flex space-x-1 list-outside list-none ml-0">
        {post.frontmatter.tags &&
          post.frontmatter.tags.map(tag => (
            <li key={tag} className="text-teal-500">
              {tag}
            </li>
          ))}
      </ul>
      <header className="px-3">
        <h3 className="font-bold tracking-normal text-xl my-3">
          <Link className="no-underline cursor-pointer" to={`/blog${post.fields.slug}`}>
            {title}
          </Link>
        </h3>
      </header>
      <section className="px-3 flex-1">
        <p
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
        />
      </section>
      <div className="px-3 pb-4 mt-3 flex-shrink-0">
        <small className="text-gray-400">{post.frontmatter.date}</small>
      </div>
    </article>
  );
};

export default PostCard;
